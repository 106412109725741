.spinnerHolder .spinner {
    z-index: 9999;
    @apply
    fixed
    h-screen
    top-0
    bottom-0
    left-0
    right-0
    bg-gray-400
    opacity-40
    flex
    justify-center
    align-middle
    justify-items-center
    flex-col
}