@import "themes.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
}
.theme-ubuntu-psychoterapia {
    background: url("./themes/ubuntu-psychoterapia/psychoterapia_main_background1.jpg");
}

.theme-ubuntu-psychoterapia .bookng-form {
    background-color: rgb(72,169,254, .4);
    @apply
        p-7
        rounded-2xl
        drop-shadow
    ;
}

.z-99999 {
    z-index: 99999;
}

/*.rdtPicker {
    position: fixed !important;
}*/

.modal-box {
    overflow: visible;
}